import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Edit, SimpleForm, ImageField, DisabledInput, LongTextInput,
  ArrayInput, withDataProvider, showNotification, GET_LIST
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import FormIterator from '../../../core/FormIterator';
import EditViewToolbarWithoutDelete from '../../../commonComponents/EditViewToolbarWithoutDelete';

const styles = {
  arrayInput: {
    width: '100%',
    marginTop: 0,
  },

  productDescriptionInput: {
    marginTop: 0
  }
};

const ReasonsEdit = ({dispatch, classes, dataProvider, showNotification, ...props}) => {
  const [selectOptionsList, setSelectOptionsList] = useState([]);

  useEffect(() => {
    dataProvider(GET_LIST, `admin/products`)
      .then(res => {
        setSelectOptionsList(res.data);
      })
      .catch(err => {
        console.error(err);
        showNotification('Ошибка: Невозможно извлечь список продуктов', 'warning');
      });
  }, [dataProvider, showNotification]);

  return (
    <Edit title="Изменение списка продуктов для повода" undoable={false} {...props}>
      <SimpleForm toolbar={<EditViewToolbarWithoutDelete />}>
        <DisabledInput label="Название повода" source="text" />
        <ArrayInput label="" source="products" className={classes.arrayInput}>
          <FormIterator isFormWithSelect={true} selectOptionsList={selectOptionsList} setSelectOptionsList={setSelectOptionsList} formTypeTogglerLabel="Список продуктов" >
            <DisabledInput label="Название продукта" source="name" />
            <ImageField label="Изображение" source="image" />
            <LongTextInput label="Описание продукта" source="description" className={classes.productDescriptionInput} />
          </FormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
};

export default connect(null, { showNotification })(withStyles(styles)(withDataProvider(ReasonsEdit)));
