import React from 'react';
import {
  TextField,
  ArrayField,
  Datagrid,
  Tab,
  TabbedShowLayout,
  ShowController,
  ShowView,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';


import ShowViewTitle from '../../../commonComponents/ShowViewTitle';
import ImageField from '../../../commonComponents/ImageField';
import ShowViewNoDataField from '../../../commonComponents/ShowViewNoDataField';
import DateField from '../../../commonComponents/DateField';

const styles = {
  reviewTitleField: {
    width: '20%',
  },
  reviewTextField: {
    textAlign: 'justify',
  }
};

export const ProductsShow = ({classes, ...props}) => {
  return (
    <ShowController {...props}>
      {({ translate, ...controllerProps }) =>
        <ShowView title={<ShowViewTitle />} {...props} {...controllerProps}>
          <TabbedShowLayout>
            <Tab label="О продукте">
              <TextField source="name" label="Название" />
              <ImageField label="Изображение" addLabel={true} source="image" />
              <TextField source="type" label="Тип" />
              <TextField source="sugar" label="Содержание сахара" />
              <TextField source="alcohol" label="Содержание алкоголя" />
              {
                controllerProps.record && controllerProps.record.aromatics && controllerProps.record.aromatics.length
                  ? (
                    <ArrayField source="aromatics" label="Ароматика">
                      <Datagrid>
                        <ImageField source="image" label="Изображение" />
                        <TextField source="name" label="Название" />
                      </Datagrid>
                    </ArrayField>
                  )
                  : <ShowViewNoDataField label="Ароматика" addLabel={true} message="Ароматики не добавлены" />
              }
              {
                controllerProps.record && controllerProps.record.averageRating
                  ? <TextField source="averageRating" label="Рейтинг" />
                  : <ShowViewNoDataField label="Рейтинг" addLabel={true} message="У продукта нет оценок" />
              }
            </Tab>
            <Tab label="Отзывы" path="reviews">
              {
                controllerProps.record && controllerProps.record.reviews && controllerProps.record.reviews.length
                  ? (
                    <ArrayField source="reviews" label="">
                      <Datagrid>
                        <TextField source="userId" label="Идентификатор пользователя" />
                        <DateField source="date" label="Дата" />
                        <TextField source="title" label="Название" headerClassName={classes.reviewTitleField} />
                        <TextField source="text" label="Текст" cellClassName={classes.reviewTextField} />
                        <TextField source="rating" label="Рейтинг" textAlign="right" />
                      </Datagrid>
                    </ArrayField>
                  )
                  : <ShowViewNoDataField message="У продукта нет отзывов" />
              }
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      }
    </ShowController>
  )
};

export default withStyles(styles)(ProductsShow);
