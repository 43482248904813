import PollIcon from '@material-ui/icons/Description';
import PollsList from './components/PollsList';
import PollShow from './components/PollShow';
import PollCreate from './components/PollCreate';

const options = {
  label: 'Опросы'
};

export default {
  icon: PollIcon,
  name: 'admin/polls',
  list: PollsList,
  show: PollShow,
  create: PollCreate,
  options
};