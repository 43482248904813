export const serverApiUrl = 'http://api.staging.jticonnex.com:3011/api/v1';

export const productTypes = [
  { id: 'Белое' },
  { id: 'Красное' },
  { id: 'Розовое' },
];

export const productSugarTypes = [
  { id: 'Сухое' },
  { id: 'Полусухое' },
  { id: 'Полусладкое' },
  { id: 'Сладкое' },
];

export const genderTypes = [
  { id: 'male', name: 'Мужской' },
  { id: 'female', name: 'Женский' },
  { id: 'common', name: 'Общий' },
];

export const pollsQuestionTypes = [
  { id: 'free', name: 'Без вариантов ответа' },
  { id: 'predefined', name: 'С вариантами ответа' },
];