import React from 'react';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    marginTop: 15,
  },
};

const ShowViewNoDataField = ({ classes, ...props }) => {
  return (
    <Typography classes={classes}>
      {props.message}
    </Typography>
  );
};

export default withStyles(styles)(ShowViewNoDataField);