import React, {
  Component,
} from 'react';
import { Notification } from 'react-admin';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  createStyles,
} from '@material-ui/core/styles';

import LoginForm from './LoginForm';

const styles = (theme) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      height: '1px',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: '#2196f3',
    },
    card: {
      minWidth: 350,
      padding: '15px 45px 40px',
      boxSizing: 'border-box',
      borderRadius: 8,
      boxShadow: '0px 20px 25px rgba(0, 0, 0, 0.15), 0px 5px 9px rgba(0, 0, 0, 0.3)',
    },
  });


class Login extends Component {
  theme = createMuiTheme(this.props.theme);

  containerRef = React.createRef();

  backgroundImageLoaded = false;

  updateBackgroundImage = () => {
    if (!this.backgroundImageLoaded && this.containerRef.current) {
      const { backgroundImage } = this.props;
      this.containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      this.backgroundImageLoaded = true;
    }
  };

  lazyLoadBackgroundImage() {
    const { backgroundImage } = this.props;

    if (backgroundImage) {
      const img = new Image();
      img.onload = this.updateBackgroundImage;
      img.src = backgroundImage;
    }
  }

  componentDidMount() {
    this.lazyLoadBackgroundImage();
  }

  componentDidUpdate() {
    if (!this.backgroundImageLoaded) {
      this.lazyLoadBackgroundImage();
    }
  }

  render() {
    const {
      backgroundImage,
      classes,
      className,
      loginForm,
      staticContext,
      ...rest
    } = this.props;

    return (
      <MuiThemeProvider theme={this.theme}>
        <div
          className={classnames(classes.main, className)}
          {...rest}
          ref={this.containerRef}
        >
          <Card className={classes.card}>
            <LoginForm />
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

const EnhancedLogin = withStyles(styles)(Login);

export default EnhancedLogin;