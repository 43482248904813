import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, withDataProvider, showNotification, refreshView , GET_ONE, UPDATE } from 'react-admin';

import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

const ChangeProductPosition = ({ dataProvider, showNotification, refreshView, ...props }) => {
  const { id, position } = props.record;
  const [isOpen, setOpenStatus] = useState(false);
  const [positionsList, setPositionsList] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(position);

  useEffect(() => {
    setSelectedPosition(position);
  }, [position]);

  const handleOpenModal = e => {
    e.stopPropagation();
    dataProvider(GET_ONE, `${props.resource}/last-position`, {})
      .then(res => {
        const positions = Array.from({ length: res.data.lastPosition }, (v, k) => k + 1);
        setPositionsList(positions);
        setOpenStatus(true);
      })
      .catch(err => {
        console.error(err);
        showNotification('Ошибка: Невозможно извлечь список позиций', 'warning');
      });
  };


  const handleCloseModal = () => {
    setOpenStatus(false);
  };

  const handleSendMessage = () => {
    const data = {
      id,
      position: selectedPosition,
    };

    dataProvider(UPDATE, `${props.resource}/position`, { data })
      .then(() => {
        handleCloseModal();
        refreshView();
      })
      .catch(err => {
        console.error(err);
        showNotification('Ошибка: Позиция не была обновлена', 'warning');
      });
  };

  return (
    <>
      <Button onClick={handleOpenModal} label="Изменить позицию">
        <UnfoldMoreIcon />
      </Button>
      <Dialog open={isOpen} onClick={e => { e.stopPropagation() }} onClose={handleCloseModal} >
        <DialogTitle>Изменить позицию продукта</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel htmlFor="user-picker">Список доступных позиций</InputLabel>
            <Select
              value={selectedPosition}
              onChange={(e) => { setSelectedPosition(e.target.value) }}
              autoWidth={false}
            >
              {
                positionsList.map(position => (
                  <MenuItem key={position} value={position}>{position}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary" label="Отмена" />
          <Button onClick={handleSendMessage} disabled={selectedPosition === position} label="Продолжить" />
        </DialogActions>
      </Dialog>
    </>
  )
};

export default connect(
  null,
  { showNotification, refreshView}
)(withDataProvider(ChangeProductPosition));