import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import MomentUtils  from 'material-ui-pickers/utils/moment-utils';
import moment from "moment";
import "moment/locale/ru";

moment.locale('ru');

const dateTimeInputOptions = {
  format: 'DD.MM.YYYY, HH:mm',
  ampm: false,
  cancelLabel: "Отмена",
  okLabel: "Продолжить"
};

const dateTimeInputProviderOptions = {
  libInstance: moment,
  utils: MomentUtils ,
  locale: 'ru'
};

const validateTastingCreation = (values) => {
  const errors = {};
  const { startDate, endDate } = values;

  if (+startDate >= +endDate) {
      errors.endDate = ['Дата окончания должна быть больше даты начала'];
  }
  return errors
};

const TastingCreate = props => {
  return (
    <Create title="Добавить дегустацию" {...props}>
      <SimpleForm redirect="list" validate={validateTastingCreation}>
        <TextInput label="Город" source="city" validate={required()} />
        <TextInput label="Адрес" source="address" validate={required()} />
        <TextInput label="Место" source="place" validate={required()} />
        <DateTimeInput label="Дата начала" source="startDate" validate={required()}
          options={dateTimeInputOptions}
          providerOptions={dateTimeInputProviderOptions}
        />
        <DateTimeInput label="Дата окончания" source="endDate" validate={required()}
          options={dateTimeInputOptions}
          providerOptions={dateTimeInputProviderOptions}
        />
      </SimpleForm>
    </Create>
  );
}

export default TastingCreate;