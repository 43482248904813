import NewsIcon from '@material-ui/icons/FormatAlignLeft';
import NewsList from './components/NewsList';
import NewsCreate from './components/NewsCreate';

const options = {
  label: 'Новости'
};

export default {
  icon: NewsIcon,
  name: 'admin/news',
  list: NewsList,
  create: NewsCreate,
  options
};