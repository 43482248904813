import React from 'react';
import { createBrowserHistory } from 'history';
import { Admin } from 'react-admin';
import russianMessages from 'ra-language-russian';

import authProvider from './core/authProvider';
import serverAPIProvider from './core/serverAPIProvider';
import httpClient from './core/httpClient';
import { serverApiUrl } from './config/constants';
import Resource from './commonComponents/ResourceWithAuthorization';

import LoginPage from './pages/loginPage/LoginPage';
import products from './pages/products';
import assistant from './pages/assistant';
import news from './pages/news';
import tastings from './pages/tastings';
import polls from './pages/polls';
import admin from './pages/admin';

const dataProvider = serverAPIProvider(serverApiUrl, httpClient);
const history = createBrowserHistory();

russianMessages.ra.action.confirm = 'Подтвердить';
const messages = {
  ru: russianMessages,
};

const i18nProvider = locale => messages[locale];

const App = () => (
<Admin dataProvider={dataProvider} authProvider={authProvider} loginPage={LoginPage} history={history} locale="ru" i18nProvider={i18nProvider} >
 <Resource {...products} />
 <Resource {...news} />
 <Resource {...polls} />
 <Resource {...tastings} />
 <Resource {...assistant} />
 <Resource {...admin} />

 <Resource name="admin/aromatics" />
</Admin>
);

export default App;