import React from 'react';
import { Create, SimpleForm, TextInput, LongTextInput, required } from 'react-admin';
import UploadImageInput from '../../../commonComponents/UploadImageInput';

const NewsCreate = props => {
  return (
    <Create title="Добавить новость" {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Заголовок" source="title" validate={required()} />
        <LongTextInput label="Содержание" source="text" validate={required()} />
        <UploadImageInput />
      </SimpleForm>
    </Create>
  );
}

export default NewsCreate;