import React from 'react';
import { Filter, SelectInput } from 'react-admin';

import { genderTypes } from '../../../config/constants';

const ReasonsFilter = (props) => (
  <Filter {...props}>
    <SelectInput label="Тип повода" source="gender" choices={genderTypes} allowEmpty={false} alwaysOn />
  </Filter>
);

export default ReasonsFilter;