import React from 'react';
import { connect } from 'react-redux';
import { Button, withDataProvider, UPDATE, showNotification, refreshView } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';

const ClosePoll = ({ dataProvider, showNotification, refreshView, ...props }) => {
  const { id, isActive } = props.record;
  const handleButtonClick = e => {
    e.stopPropagation();
    const data = {
      id,
      isActive: false,
    };

    dataProvider(UPDATE, `${props.resource}/status`, { data })
      .then(() => {
        refreshView();
      })
      .catch(err => {
        console.error(err);
        showNotification('Ошибка: Не удалось закрыть опрос', 'warning');
      });
  };

  return (
    <>
      <Button onClick={handleButtonClick} label="Закрыть" disabled={!isActive} >
        <CloseIcon />
      </Button>
    </>
  );
};

export default connect(
  null,
  {
    refreshView,
    showNotification
  },
)(withDataProvider(ClosePoll));
