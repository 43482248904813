import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
} from 'react-admin';

import { genderTypes } from '../../../config/constants';
import ReasonsFilter from './ReasonsFilter';

const ProductsList = props => {
  return (
    <List
      title="Помощник"
      exporter={false}
      {...props}
      filters={<ReasonsFilter />}
      filterDefaultValues={{ gender: genderTypes[0].id }}
      bulkActionButtons={false}
      pagination={<></>}
    >
      <Datagrid rowClick="edit">
        <TextField label="Название" source="text" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ProductsList;
