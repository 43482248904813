import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShownPasswordIcon from '@material-ui/icons/Visibility';
import HiddenPasswordIcon from '@material-ui/icons/VisibilityOff';
import {
  withStyles,
  createStyles,
} from '@material-ui/core/styles';
import {
  withTranslate,
  userLogin,
} from 'ra-core';

const styles = ({ spacing }) =>
  createStyles({
    form: {
      
    },
    input: {
      marginTop: '1em',
      position: 'relative',
    },
    buttonContainer: {
      justifyContent: 'center',
      marginTop: 45,
    },
    button: {
      width: 150,
      height: 40,
      textTransform: 'none',
      fontWeight: 'bold',
    },
    icon: {
      marginRight: spacing.unit,
    },
    passwordVisibilityButton: {
      width: '0.8em',
      height: '0.8em',
      position: 'absolute',
      right: 4,
      bottom: 6,
      cursor: 'pointer',
    },
    passwordInput: {
        paddingRight: 30,
    },
  });

const renderInput = ({
  meta: { touched, error } = { touched: false, error: '' },
  input: { ...inputProps },
  ...props
}) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
    />
  );
const login = (auth, dispatch, { redirectTo }) =>
  dispatch(userLogin(auth, redirectTo));

const LoginForm = ({
  classes,
  isLoading,
  handleSubmit,
  translate,
}) => {
  const [passwordFieldType, changePasswordFieldType] = useState('password');

  return (
    <form onSubmit={handleSubmit(login)}>
      <div className={classes.form}>
        <div className={classes.input}>
          <Field
            autoFocus
            id="username"
            name="username"
            component={renderInput}
            label="Логин"
            disabled={isLoading}
          />
        </div>
        <div className={classes.input}>
          <Field
            style={{}}
            id="password"
            name="password"
            component={renderInput}
            label={translate('ra.auth.password')}
            type={passwordFieldType}
            disabled={isLoading}
            inputProps={{
              className: classes.passwordInput,
            }}
          />
          {
            passwordFieldType === 'password'
              ? <HiddenPasswordIcon className={classes.passwordVisibilityButton} onClick={() => { changePasswordFieldType('text') }} />
              : <ShownPasswordIcon className={classes.passwordVisibilityButton} onClick={() => { changePasswordFieldType('password') }} />
          }
        </div>
      </div>
      <CardActions className={classes.buttonContainer}>
        <Button
          variant="raised"
          type="submit"
          color="primary"
          disabled={isLoading}
          className={classes.button}
        >
          {isLoading && (
            <CircularProgress
              className={classes.icon}
              size={18}
              thickness={2}
            />
          )}
          {translate('ra.auth.sign_in')}
        </Button>
      </CardActions>
    </form>
  )
};

const mapStateToProps = (state) => ({
  isLoading: state.admin.loading > 0,
});

const enhance = compose(
  withStyles(styles),
  withTranslate,
  connect(mapStateToProps),
  reduxForm({
    form: 'signIn',
  }),
);

const EnhancedLoginForm = enhance(LoginForm);

EnhancedLoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

export default EnhancedLoginForm;