import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import ImageField from '../../../commonComponents/ImageField';
import ChangeProductPosition from './customActions/ChangeProductPosition';

const styles ={
  imageField: {
    paddingTop: 5
  },
  row: {
    height: 90
  },
};

const ProductsList = ({ classes, ...props }) => {
  return (
    <List
      title="Продукты"
      exporter={false}
      {...props}
      bulkActionButtons={false}
      pagination={<></>}
    >
      <Datagrid rowClick="show" classes={{row: classes.row}}>
        <ImageField label="Изображение" source="image" cellClassName={classes.imageField} sortable={false} />
        <TextField label="Название" source="name" sortable={false} />
        <TextField label="Содержание сахара" source="sugar" sortable={false} />
        <TextField label="Рейтинг" source="averageRating" sortable={false} />
        <TextField label="Позиция" source="position" sortable={false} />
        <ChangeProductPosition />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  );
};

export default withStyles(styles)(ProductsList);
