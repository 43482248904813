import React from 'react';
import { Edit, SimpleForm, TextInput, DisabledInput, required } from 'react-admin';

import EditViewToolbarWithoutDelete from '../../../commonComponents/EditViewToolbarWithoutDelete';

export const confirmedPasswordValidation = (confirmedPassword, allValues) => {
  return confirmedPassword !== allValues.password ? 'Пароли не совпадают' : undefined ;
};

const AdminEdit = props => (
  <Edit title="Изменение пароля администратора" undoable={false} {...props}>
    <SimpleForm  toolbar={<EditViewToolbarWithoutDelete />}>
      <DisabledInput label="Имя администратора" source="login" />
      <TextInput label="Новый пароль" source="password" type="password" validate={required()} />
      <TextInput label="Повторите пароль" source="confirm_password" validate={[confirmedPasswordValidation, required()]} type="password" />
    </SimpleForm>
  </Edit>
);

export default AdminEdit;