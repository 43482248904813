import React, {
  Children,
  cloneElement,
  Component,
} from 'react';
import { FormInput, Labeled } from 'react-admin';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { translate } from 'ra-core';
import classNames from 'classnames';

import ImageField from '../commonComponents/ImageField';

const styles = theme =>
  createStyles({
    root: {
      padding: 0,
      marginTop: 0,
      marginBottom: 0,
      '& > li:last-child': {
        borderBottom: 'none',
      },
    },
    formTypeTogglerSection: {
      width: 256,
    },
    line: {
      display: 'flex',
      listStyleType: 'none',
      [theme.breakpoints.down('xs')]: { display: 'block' },
      '&.fade-enter': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
      },
      '&.fade-enter-active': {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'all 500ms ease-in',
      },
      '&.fade-exit': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '&.fade-exit-active': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
        transition: 'all 500ms ease-in',
      },
    },
    addNewFormSection: {
      marginBottom: -65,
    },
    index: {
      width: '3em',
      paddingTop: '1em',
      [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    form: {
      flex: 2,
      marginTop: 15,
    },
    action: {
      paddingTop: '0.5em',
      '& .button-add': {
        marginTop: 4,
      },
      '& .button-remove': {
        marginTop: 35,
      },
    },
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
    imageFieldLabel: {
      color: 'rgba(0, 0, 0, 0.38)'
    },
  });

export class FormIterator extends Component {
  constructor(props) {
    super(props);
    // we need a unique id for each field for a proper enter/exit animation
    // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
    // so we keep an internal map between the field position and an autoincrement id
    this.nextId = props.fields.length
      ? props.fields.length
      : props.defaultValue
        ? props.defaultValue.length
        : 0;

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : [];

    this.state = {
      selectedOptionId: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (this.ids.length === 0 && prevProps.fields.length === 0 && this.props.fields.length > 0) {
      this.nextId = this.props.fields.length;

      this.ids = Array.from(Array(this.nextId).keys());
    }
  }

  preserveProductDescription = removedProduct => {
    const updatedSelectOptionsList = this.props.selectOptionsList.map(i => i.id === removedProduct._id
      ? { ...i, description: removedProduct.description }
      : i
    );

    this.props.setSelectOptionsList(updatedSelectOptionsList);
  };

  removeField = (index, isFormWithSelect) => () => {
    const { fields } = this.props;
  
    if (isFormWithSelect) {
      const removedProduct = fields.get(index);
      this.preserveProductDescription(removedProduct);
    }

    this.ids.splice(index, 1);
    fields.remove(index);
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determing if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === 'boolean') {
      return disableRemove;
    }
    return disableRemove && disableRemove(record);
  };

  addField = isFormWithSelect => () => {
    const { fields } = this.props;
    this.ids.push(this.nextId++);
    const selectedProduct = isFormWithSelect ? this.props.selectOptionsList.find(i => i.id === this.state.selectedOptionId) : {};
    const { id, name, description, image } = selectedProduct;
    const newFieldProps = isFormWithSelect ? { _id: id, name, description, image } : {};

    fields.push(newFieldProps);
  };

  render() {
    const {
      classes = {},
      fields,
      children,
      resource,
      basePath,
      meta: { error, submitFailed },
      record,
      source,
      translate,
      disableAdd,
      disableRemove,
      selectOptionsList = [],
      isRemoveButtonMuted,
      isFormWithSelect
    } = this.props;
    const records = get(record, source);


    const selectedOptions = fields.map((field, i) => fields.get(i)._id);
    let optionsListWithoutSelectedOptions = selectOptionsList;

    if (selectOptionsList.length && selectedOptions.length) {
      optionsListWithoutSelectedOptions = selectOptionsList.filter(product => !selectedOptions.some(selectedProductId => selectedProductId === product.id));
    }

    return fields ? (
      <ul className={classes.root}>
        {submitFailed && error && (
          <FormHelperText error>{error}</FormHelperText>
        )}
        {!disableAdd && (
          <li className={classes.line}>
            {isFormWithSelect && (
              <section>
                <FormControl className={classes.formTypeTogglerSection}>
                  <InputLabel htmlFor="question-type-picker">{this.props.formTypeTogglerLabel}</InputLabel>
                  <Select
                    value={this.state.selectedOptionId}
                    onChange={(e) => { this.setState({ selectedOptionId: e.target.value }) }}
                    inputProps={{
                      id: 'question-type-picker'
                    }}
                    autoWidth={false}
                  >
                    {
                      optionsListWithoutSelectedOptions.map(item => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </section>
            )}
            <span className={classes.action}>
              <Button
                className={classNames(
                  'button-add',
                  `button-add-${source}`
                )}
                size="small"
                disabled={isFormWithSelect ? !this.state.selectedOptionId: false}
                onClick={this.addField(isFormWithSelect)}
              >
                <AddIcon className={classes.leftIcon} />
                {translate('ra.action.add')}
              </Button>
            </span>
          </li>
        )}
        <TransitionGroup>
          {fields.map((member, index) => {
            return (
              <CSSTransition
                key={this.ids[index] || index}
                timeout={500}
                classNames="fade"
              >
                <li className={classes.line}>
                  <section className={classes.form}>
                    {Children.map(children, (input, index2) => {
                      const record = (records && records[index]) || {};
                      const source = input.props.source
                        ? `${member}.${
                        input.props.source
                        }`
                        : member;

                      return input.props.source === 'image'
                        ? (
                          <Labeled label={input.props.label} classes={{ label: classes.imageFieldLabel }}>
                            <ImageField src={fields.get(index).image} source={source} record={record} />
                          </Labeled>
                        )
                        : (
                          <FormInput
                            basePath={
                              input.props.basePath || basePath
                            }
                            input={cloneElement(input, {
                              source,
                              index: input.props.source
                                ? undefined
                                : index2,
                              label:
                                input.props.label ||
                                input.props.source,
                            })}
                            record={record}
                            resource={resource}
                          />
                        )
                    })}
                  </section>
                  {!disableRemove && (
                    <span className={classes.action}>
                      <Button
                        className={classNames(
                          'button-remove',
                          `button-remove-${source}-${index}`
                        )}
                        size="small"
                        onClick={this.removeField(index, isFormWithSelect)}
                        disabled={isRemoveButtonMuted}
                      >
                        <CloseIcon
                          className={classes.leftIcon}
                        />
                        {translate('ra.action.remove')}
                      </Button>
                    </span>
                  )}
                </li>
              </CSSTransition>
            )
          })}
        </TransitionGroup>
      </ul>
    ) : null;
  }
}

FormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

FormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default compose(
  translate,
  withStyles(styles)
)(FormIterator);