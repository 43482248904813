import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import httpClient from '../core/httpClient';

import { serverApiUrl } from '../config/constants';

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const url = `${serverApiUrl}/admin/auth`;
    const options = {
      method: 'POST',
      body: JSON.stringify({ login: username, password }),
    };
    let token = null;

    try {
      const { json } = await httpClient(url, options);
      token = json.token;

      localStorage.setItem('token', token);
    } catch (err) {
      throw new Error('Данные введены неверно');
    }

    return Promise.resolve();
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');

    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401) {
      localStorage.removeItem('token');
      return Promise.reject();
    }

    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('token')
      ? Promise.resolve()
      : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return localStorage.getItem('token')
      ? Promise.resolve(true)
      : Promise.reject();
  }

  return Promise.reject('Unknown method');
};