import ProductsIcon from '@material-ui/icons/LocalBar';
import ProductsList from './components/ProductsList';
import ProductsShow from './components/ProductsShow';
import ProductCreate from './components/ProductCreate';

const options = {
  label: 'Продукция'
};

export default {
  icon: ProductsIcon,
  name: 'admin/products',
  list: ProductsList,
  show: ProductsShow,
  create: ProductCreate,
  options
};