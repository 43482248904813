import TastingIcon from '@material-ui/icons/LocalDrink';
import TastingsList from './components/TastingsList';
import TastingCreate from './components/TastingCreate';

const options = {
  label: 'Дегустации'
};

export default {
  icon: TastingIcon,
  name: 'admin/tastings',
  list: TastingsList,
  create: TastingCreate,
  options
};