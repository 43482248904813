import PersonIcon from '@material-ui/icons/Person';
import AdminList from './components/AdminList';
import AdminEdit from './components/AdminEdit';

const options = {
  label: 'Администратор'
};

export default {
  icon: PersonIcon,
  name: 'admin/user',
  list: AdminList,
  edit: AdminEdit,
  options
};