import React from 'react';
import { ImageInput, ImageField, showNotification, REDUX_FORM_NAME } from 'react-admin';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';

import httpClient from '../core/httpClient';
import { serverApiUrl } from '../config/constants';

const styles = {
  image: {
    maxHeight: '3rem',
  },
  removeButton: {
    paddingRight: '48px',
  },
  dropZone: {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f0f0f0',
    display: 'inline-block',
    padding: '0.5rem',
    margin: '20px 10px 20px 0',
    borderRadius: '4px',
    '& + .previews': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
};

const UploadImageInput = ({
  classes,
  change,
  showNotification,
  formName = REDUX_FORM_NAME,
  label = '',
  imageInputSource = 'image',
  imagePreviewSource = 'photo',
  ...rest
}) => {
  const uploadImage = file => {
    const formData = new FormData();
    formData.append('photo', file[0]);
    const url = `${serverApiUrl}/admin/upload/image`;
    const options = { method: 'POST', body: formData };

    httpClient(url, options)
      .then(res => {
        const image = res.json.url;
        change(formName, imageInputSource, image);
      })
      .catch(() => {
        showNotification("Ошибка: Изображение не было загружено", 'warning');
      });
  };

  const formatFunc = value => {
    if (typeof value === 'string') {
      return { photo: value };
    }

    return value;
  };

  return (
    <ImageInput
      classes={{ dropZone: classes.dropZone, removeButton: classes.removeButton }}
      format={formatFunc}
      source={imageInputSource}
      label={label}
      accept="image/*"
      placeholder={<span>Загрузить картинку</span>}
      options={{ onDropAccepted: uploadImage }}
      {...rest}
    >
      <ImageField classes={{ image: classes.image }} source={imagePreviewSource} />
    </ImageInput>
  );
};

export default connect(null, { change, showNotification })(withStyles(styles)(UploadImageInput));
