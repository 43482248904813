import React from 'react';
import ModalImage from 'react-modal-image';

const ImageField = ({ record, source, src = record[source] }) => {
  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      className="image-container"
    >
      <ModalImage
        small={src}
        large={src}
        hideDownload={true}
        hideZoom={true}
        className="image-container__image_small"
        imageBackgroundColor="transparent"
        alt="Изображение отсутсвует"
      />
    </div>
  );
};

export default ImageField;
