import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { autofill, formValueSelector } from 'redux-form';
import { Create, SimpleForm, TextInput, RadioButtonGroupInput, ArrayInput, required, REDUX_FORM_NAME } from 'react-admin';

import { pollsQuestionTypes } from '../../../config/constants';
import FormIterator from '../../../core/FormIterator';

const PollCreate = ({ classes, autofill, questionType, choices = [], ...props }) => {
  useEffect(() => {
    const choicesFieldValue = questionType === 'free' ? undefined : [{}, {}];

    autofill(REDUX_FORM_NAME, 'choices', choicesFieldValue);
  }, [questionType, autofill]);

  return (
    <Create title="Добавить опрос" {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Название" source="title" validate={required()} />
        <RadioButtonGroupInput label="Тип вопроса" source="type" choices={pollsQuestionTypes} defaultValue={pollsQuestionTypes[0].id} />
        {
          questionType === 'predefined' && (
            <ArrayInput label="Варианты ответов" source="choices">
              <FormIterator isFormWithSelect={false} isRemoveButtonMuted={choices.length < 3} >
                <TextInput label="Ответ на вопрос" source="text" />
              </FormIterator>
            </ArrayInput>
          )
        }
      </SimpleForm>
    </Create>
  );
}

const selector = formValueSelector(REDUX_FORM_NAME);

const mapStateToProps = (state) => {
  return {
    questionType: selector(state, 'type'),
    choices: selector(state, 'choices'),
  }
};

export default connect(mapStateToProps, { autofill })(PollCreate);