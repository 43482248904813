import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, SelectArrayInput, NumberInput, ReferenceArrayInput, required } from 'react-admin';
import UploadImageInput from '../../../commonComponents/UploadImageInput';
import { productTypes, productSugarTypes } from '../../../config/constants';

const ProductCreate = props => {
  return (
    <Create title="Добавить продукт" {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Название" source="name" validate={required()} />
        <UploadImageInput />
        <SelectInput label="Тип" source="type" optionText="id" choices={productTypes} validate={required()} />
        <SelectInput label="Содержание сахара" source="sugar" optionText="id" choices={productSugarTypes} validate={required()} />
        <NumberInput label="Содержание алкоголя" source="alcohol" validate={required()} />
        <ReferenceArrayInput label="Ароматика" source="aromatics" reference="admin/aromatics" perPage={1000}>
          <SelectArrayInput />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
}

export default ProductCreate;