import {
  fetchUtils,
} from 'react-admin';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  token && options.headers.set('x-auth-token', token);

  return fetchUtils.fetchJson(url, options);
}

export default httpClient;