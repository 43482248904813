import React from 'react';
import {
  TextField,
  ArrayField,
  FunctionField,
  Datagrid,
  Tab,
  TabbedShowLayout,
  ShowController,
  ShowView,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import ShowViewNoDataField from '../../../commonComponents/ShowViewNoDataField';
import { pollsQuestionTypes } from '../../../config/constants';

const styles = {
  reviewTextField: {
    textAlign: 'justify',
  },
  thead: {
    display: 'none'
  }
};

export const PollShow = ({ classes, ...props }) => {
  return (
    <ShowController {...props}>
      {({ translate, ...controllerProps }) =>
        <ShowView title="Страница опроса" {...props} {...controllerProps}>
          <TabbedShowLayout>
            <Tab label="Опрос">
              <TextField source="title" label="Вопрос" />
              <FunctionField
                label="Тип вопроса"
                render={record => record.type ? pollsQuestionTypes.find(i => i.id === record.type).name : ''}
              />
              {
                controllerProps.record && controllerProps.record.type !== pollsQuestionTypes[0].id && (
                  <ArrayField source="choices" label="Варианты ответа">
                    <Datagrid classes={{ thead: classes.thead }}>
                      <TextField source="text" />
                    </Datagrid>
                  </ArrayField>
                )
              }
              <FunctionField label="Статус" render={record => record.isActive ? 'Активен' : 'Закрыт'} />
            </Tab>
            <Tab label="Ответы пользователей" path="answers">
              {
                controllerProps.record && controllerProps.record.answers && controllerProps.record.answers.length
                  ? (
                    <ArrayField source="answers" label="">
                      <Datagrid>
                        <TextField source="userId" label="Идентификатор пользователя" />
                        <TextField source="text" label="Ответ пользователя" className={classes.reviewTextField} />
                      </Datagrid>
                    </ArrayField>
                  )
                  : <ShowViewNoDataField message="У опроса нет ответов" />
              }
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      }
    </ShowController>
  )
};

export default withStyles(styles)(PollShow);
