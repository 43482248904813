import HelpIcon from '@material-ui/icons/Help';
import ReasonsList from './components/ReasonsList';
import ReasonsEdit from './components/ReasonsEdit';

const options = {
  label: 'Помощник'
};

export default {
  icon: HelpIcon,
  name: 'admin/helper/reasons',
  list: ReasonsList,
  edit: ReasonsEdit,
  options
};