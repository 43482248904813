import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
} from 'react-admin';

const AdminList = props => {
  return (
    <List
      title="Администратор"
      exporter={false}
      {...props}
      bulkActionButtons={false}
      pagination={<></>}
    >
      <Datagrid rowClick="edit">
        <TextField label="Имя администратора" source="login" sortable={false} />
        <EditButton label="Изменить пароль" />
      </Datagrid>
    </List>
  );
};

export default AdminList;
