import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
} from 'react-admin';

import DateField from '../../../commonComponents/DateField';

const TastingsList = ({ classes, ...props }) => {
  return (
    <List
      title="Дегустации"
      exporter={false}
      {...props}
      bulkActionButtons={false}
      pagination={<></>}
    >
      <Datagrid>
        <TextField label="Город" source="city" sortable={false} />
        <TextField label="Адрес" source="address" sortable={false} />
        <TextField label="Место" source="place" sortable={false} />
        <DateField label="Дата начала" source="startDate" sortable={false} />
        <DateField label="Дата окончания" source="endDate" sortable={false} />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  );
};

export default TastingsList;
