import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
} from 'react-admin';

import ClosePoll from './customActions/ClosePoll';

const PollsList = ({ classes, ...props }) => {
  return (
    <List
      title="Опросы"
      exporter={false}
      {...props}
      bulkActionButtons={false}
      pagination={<></>}
    >
      <Datagrid rowClick="show">
        <TextField label="Заголовок" source="title" sortable={false} />
        <ClosePoll />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  );
};

export default PollsList;
