import { stringify } from 'query-string';
import { fetchUtils, GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE, CREATE, UPDATE, DELETE } from 'react-admin';

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params ? params.pagination : {};
        const { field, order } = params ? params.sort : {};
        const query = params
          ? {
            ...params.filter,
            sort: field,
            order,
            offset: (page - 1) * perPage,
            limit: perPage,
          }
          : '';
        const queryUrlPart = query ? `?${stringify(query)}` : query;

        url = `${apiUrl}/${resource}${queryUrlPart}`;
        break;
      }
      case GET_MANY: {
        url = `${apiUrl}/${resource}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}${params.id ? '/' + params.id : ''}`;
        break;
      case UPDATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'PUT';
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = params.data instanceof FormData ? params.data : JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}`;
        options.method = 'DELETE';
        options.body = JSON.stringify({ id: params.id });
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response;
    let data;
    let total;

    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (json.total !== undefined) {
          data = json[resource.split('/').pop()];
          total = json.total;
        } else {
          data = json;
          total = json.length;
        }

        return {
          data,
          total,
        };
      case GET_MANY:
        if (json.total) {
          data = json[resource.split('/').pop()];
        } else {
          data = json;
        }

        return {
          data,
        };
      case GET_ONE:
        return {
          data: json,
        };
      case CREATE:
      case UPDATE:
        const id = params.data.id || json.id;
        return { data: { ...params.data, id } };
      case DELETE:
        return {
          data: { id: params.id },
        };
      default:
        return { data: json };
    }
  };

  return (type, resource, params) => {
    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response => convertHTTPResponse(response, type, resource, params));
  };
};
